import {LngLat} from "mapbox-gl";


export function wktPointToLatLng(wktPoint: string): LngLat | null {
  const matches = wktPoint.match(/POINT\s*\((.*?)\s(.*?)\)/i);

  if (matches && matches.length >= 3) {
    const lng = parseFloat(matches[1]);
    const lat = parseFloat(matches[2]);

    if (isNaN(lng) || isNaN(lat)) {
      return null;
    }

    const lngLat =  new LngLat(lng, lat);
    return  lngLat
  }

  return null;
}
