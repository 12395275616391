export enum WaypointOptionsDialogPages {
  WaypointOptions = 'WaypointOptions',
  WaypointEditDetails = 'WaypointEditDetails',
  WaypointViewDetails = 'WaypointViewDetails',
  Waypoints = 'Waypoints',
  WaypointGroups = 'WaypointGroups',
  WaypointGroupSelection = 'WaypointGroupSelection',
  WaypointGroupsWaypoints = 'WaypointGroupsWaypoints',
  WaypointDetails = 'WaypointDetails',
}
