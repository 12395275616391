import Vue from "vue";
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import graph_client from "../../../utils/graphql/graph_client";
import {
  CreateWaypoint,
  CreateWaypointGroup, CreateWaypoints,
  DestroyWaypoint, DestroyWaypointGroup,
  DestroyWaypointImage,
  GetAllUserWaypointGroups,
  GetAllWaypointsForUser,
  GetWaypointByIdOrClientId,
  GetWaypointGroupByIdOrClientId,
  UpdateWaypoint,
  UpdateWaypointGroup,
  WaypointsSearch
} from "../../../utils/graphql/waypoints-graph";
import {WaypointOptionsDialogPages} from "../../../utils/models/WaypointOptionsDialogPages";
import {wktPointToLatLng} from "../../../utils/helpers/WKTPointToLatLng";
import {
  waypointsToGeoJsonFeatureCollectionHelper
} from "../../../utils/helpers/WaypointsToGeoJsonFeatureCollectionHelper";
import {POI, POIUtility} from "../../../utils/models/POI";
import {isClickOnWaypointHelper} from "../../../utils/helpers/IsClickOnWaypointHelper";
import {OnBoardingGoal} from "../../../utils/models/OnBoardingGoal";
import firebase from "../../../js/firebase";
import {getWaypointClientIdFromUrl} from "../../../utils/helpers/GetWaypointClientIdFromUrl";
import {cleanseUrl} from "../../../utils/helpers/CleanseAndPushUrl";
import {generateRandomInt} from "../../../utils/helpers/IDGenerator";
import {createNewWaypointJsonHelper} from "../../../utils/helpers/CreateNewWaypointJsonHelper";
import {
  createIndividualWaypointGeoJsonObjectHelper
} from "../../../utils/helpers/CreateIndividualWaypointGeoJsonObjectHelper";
import {createWaypointMapLayerHelper} from "../../../utils/helpers/CreateWaypointMapLayerHelper";
import {gpxFileToGeoJsonHelper} from "../../../utils/helpers/GpxFileToGeoJsonHelper";
import {
  getBoundsFromGeoJsonPointsFeatureCollection
} from "../../../utils/helpers/GetBoundsFromGeoJsonPointsFeatureCollection";
import {addWaypointToMapHelper} from "../../../utils/helpers/AddWaypointToMapHelper";
import toGpx from "togpx";
import mapboxgl from "mapbox-gl";
import "firebase/compat/storage";

export class WaypointDialogState {
  waypointAddingModeEnabled: boolean = false;
  pageStack: Array<WaypointOptionsDialogPages> = [WaypointOptionsDialogPages.WaypointOptions];
  allUserWaypoints: Array<any> = [];
  allUserWaypointGroups: Array<any> = [];
  searchedWaypoints: Array<any> = [];
  selectedWaypointGroup: any = null;
  mapContainsWaypoints: boolean = false;

  selectedWaypoint: any = null;

  waypointsReadyForUpload: Array<any> = [];
  waypointPhotosAwaitingUpload: Array<any> = [];

  waypointPhotosToRemove: Array<any> = [];

  loading: boolean = false;

  individualWaypointLayerIds: Array<string> = [];
}

const actions: ActionTree<WaypointDialogState, any> = {
  initialiseStore(context: any, payload: any) {
    const dialog = $('#waypoint-options-dialog');
    dialog.on('hidden.bs.modal', () => {
      context.commit('resetPageStack');
      context.commit('clearSearchedWaypoints');
      context.state.waypointsReadyForUpload = [];
      context.state.waypointPhotosAwaitingUpload = [];
      context.state.waypointPhotosToRemove = [];
    });
    window.map.on('style.load', () => {
      console.log('main method style loaded in waypoints')
      if (context.state.mapContainsWaypoints) {
        context.dispatch('addWaypointsToMap')
      } else {
        context.dispatch('removeWaypointsFromMap')
      }
    })
    window.map.on('click', (e: any) => {
      const features = window.map.queryRenderedFeatures(e.point)
      if (isClickOnWaypointHelper(features)) {
        context.dispatch('getWaypointFrom', features[0].properties.clientId)
        return;
      }
      if (context.state.waypointAddingModeEnabled) {
        context.dispatch('createWaypoint', {lngLat: e.lngLat});
      }
    });
    const waypointClientId = getWaypointClientIdFromUrl();
    if (waypointClientId) {
      graph_client.request(GetWaypointByIdOrClientId, {"clientId": waypointClientId}).then((data: any) => {
        if (data.waypointByIdOrClientId) {
          context.state.selectedWaypoint = data.waypointByIdOrClientId;
          context.dispatch('showViewWaypointDetails')
          const latLng = wktPointToLatLng(context.state.selectedWaypoint.lonlat)
          // @ts-ignore
          dialog.modal('show');
          window.map.on('load', () => {
            let poiType = POIUtility.fromString(context.state.selectedWaypoint.type)
            const addedWaypointRandomId = addWaypointToMapHelper(
              // @ts-ignore
              poiType, waypointClientId, latLng
            )
            context.state.individualWaypointLayerIds.push(addedWaypointRandomId)
            const bounds = getBoundsFromGeoJsonPointsFeatureCollection(
              waypointsToGeoJsonFeatureCollectionHelper([context.state.selectedWaypoint])
            )
            const convertedBounds = [
              [bounds.southwest[1], bounds.southwest[0]],  // [minLon, minLat]
              [bounds.northeast[1], bounds.northeast[0]]   // [maxLon, maxLat]
            ];
            window.map.fitBounds(convertedBounds, {padding: 10, maxZoom: 15})
          })
        } else {
          alert("Waypoint not found")
          cleanseUrl(window.location.href)
        }
      })
    }
    // @ts-ignore
    window.addMarkerClickedFromPopup = (lngLat: mapboxgl.LngLatLike) => {
      context.dispatch('createWaypoint', {lngLat: lngLat});
      context.rootState.map.marker?.remove()
      context.rootState.map.marker = null
    }
  },
  getWaypointFrom(context: any, clientId: any) {
    graph_client.request(GetWaypointByIdOrClientId, {clientId}).then((data: any) => {
      console.log(data)
      context.state.selectedWaypoint = data.waypointByIdOrClientId;
      context.dispatch('showViewWaypointDetails')
    })
  },
  refreshSelectedWaypoint(context: any) {
    graph_client.request(GetWaypointByIdOrClientId, {clientId: context.state.selectedWaypoint.clientId}).then((data: any) => {
      context.state.selectedWaypoint = data.waypointByIdOrClientId;
    })
  },
  getAllUserWaypoints(context: any, payload: any) {
    graph_client.request(GetAllWaypointsForUser).then((data: any) => {
      context.state.allUserWaypoints = data.waypoints;
    })
  },
  deleteSelectedWaypoint(context: any) {
    graph_client.request(DestroyWaypoint, {waypoint: {id: context.state.selectedWaypoint.id}}).then((data: any) => {
      context.dispatch('getAllUserWaypoints')
      if (context.state.mapContainsWaypoints) {
        context.dispatch('removeWaypointsFromMap')
        context.dispatch('addWaypointsToMap')
      }
      const dialog = $('#waypoint-options-dialog');
      // @ts-ignore
      dialog.modal('hide');
    }).catch((error: any) => {
      console.error(error)
    })
  },
  async deleteWaypoint(context, waypoint) {
    context.state.loading = true
    try {
      const response = graph_client.request(DestroyWaypoint, {waypoint: {id: waypoint.id}})
      await context.dispatch('getAllUserWaypoints')
      setTimeout(() => {
        context.state.loading = false
        if (context.state.mapContainsWaypoints) {
          context.dispatch('removeWaypointsFromMap')
          context.dispatch('addWaypointsToMap')
        }
      }, 750)
    } catch (e) {
      console.error(e)
    }
  },
  showViewWaypointDetails(context: any) {
    if (context.state.selectedWaypoint == null) {
      throw new Error("No waypoint selected");
    }
    context.commit('setTopPage', WaypointOptionsDialogPages.WaypointViewDetails);
    const dialog = $('#waypoint-options-dialog');
    // @ts-ignore
    dialog.modal('show');
  },
  getAllUserWaypointGroups(context: any) {
    graph_client.request(GetAllUserWaypointGroups).then((data: any) => {
      context.state.allUserWaypointGroups = data.waypointGroups;
    })
  },
  updateWaypointGroup(context: any, payload: {id: string, title: string, waypointIds: number[]}) {
    graph_client.request(UpdateWaypointGroup, {waypointGroup: payload}).then((data: any) => {
      context.dispatch('getAllUserWaypointGroups')
    })
  },
  getWaypointGroup(context: any, clientId: string) {
    graph_client.request(GetWaypointGroupByIdOrClientId, {clientId: clientId}).then((data: any) => {
      context.state.selectedWaypointGroup = data.waypointGroupByIdOrClientId;
    })
  },
  createWaypointGroup(context: any, payload: {title: string, waypointIds: number[]}) {
    graph_client.request(CreateWaypointGroup, {waypointGroup: payload}).then((data: any) => {
      context.dispatch('getAllUserWaypointGroups')
    }).catch((error: any) => {
      console.error(error)
    })
  },
  async createWaypoint(context: any, payload: {lngLat, title : "Custom Waypoint", type: POI.Other, description: "" }) {
    const response = await graph_client.request(CreateWaypoint, {
      "waypoint": createNewWaypointJsonHelper(payload.lngLat.lng, payload.lngLat.lat, payload.title, payload.type, payload.description)
    })
    const addedWaypointRandomId = addWaypointToMapHelper(
      // @ts-ignore
      POIUtility.fromString(response?.createWaypoint.type), response?.createWaypoint.clientId, payload.lngLat
    )
    context.state.individualWaypointLayerIds.push(addedWaypointRandomId)
  },
  searchWaypoints(context: any, searchString: string) {
    graph_client.request(WaypointsSearch, {'searchString': searchString}).then((data: any) => {
      context.state.searchedWaypoints = data.waypointsSearch;
    })
  },
  removeAllIndividualWaypointsFromMap(context: any) {
    for (let i = 0; i < context.state.individualWaypointLayerIds.length; i++) {
      const layerId = context.state.individualWaypointLayerIds[i];
      if (window.map.getLayer(layerId) != null) {
        window.map.removeLayer(layerId)
      }
      if (window.map.getSource(layerId) != null) {
        window.map.removeSource(layerId)
      }
    }
    context.state.individualWaypointLayerIds = []
  },
  addWaypointsToMap(context: any) {
    graph_client.request(GetAllWaypointsForUser).then((data: any) => {
      const geojson = waypointsToGeoJsonFeatureCollectionHelper(data.waypoints);
      context.dispatch('removeAllIndividualWaypointsFromMap')
      if (window.map.getLayer("waypoints") != null) {
        window.map.removeLayer("waypoints")
      }
      if (window.map.getSource("waypoints") != null) {
        window.map.removeSource("waypoints")
      }
      window.map.addSource("waypoints", {
        "type": "geojson",
        "data": geojson
      })

      window.map.addLayer(createWaypointMapLayerHelper("waypoints"));
      context.state.mapContainsWaypoints = true
      context.state.loading = false;
    })
  },
  removeWaypointsFromMap(context: any) {
    if (window.map.getLayer("waypoints") != null) {
      window.map.removeLayer("waypoints")
    }
    if (window.map.getSource("waypoints") != null) {
      window.map.removeSource("waypoints")
    }
    context.state.mapContainsWaypoints = false
  },
  async uploadWaypointImages(context: any, files: any[]) {
    context.state.loading = true;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      context.state.waypointPhotosAwaitingUpload.push(file);
    }
    const storageRef = firebase.storage().ref();

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      const fileRef = storageRef.child('Public_Images/' + file.name); // Set your desired path here
      await fileRef.put(file);

      let downloadURL = await fileRef.getDownloadURL();
      context.state.selectedWaypoint.waypointPhotos.push({id: generateRandomInt(), file: {url: downloadURL}});
      context.state.waypointsReadyForUpload.push(downloadURL);
      context.state.waypointPhotosAwaitingUpload.splice(context.state.waypointPhotosAwaitingUpload.indexOf(file), 1);
    }
    context.state.loading = false;
  },
  saveSelectedWaypoint(context: any) {
    context.state.loading = true;
    if (context.state.waypointPhotosAwaitingUpload.length > 0) {
      return alert("Please wait for all images to upload")
    }
    if (context.state.waypointPhotosToRemove.length > 0) {
      for (let i = 0; i < context.state.waypointPhotosToRemove.length; i++) {
        const photo = context.state.waypointPhotosToRemove[i];
        context.dispatch('removeWaypointImage', photo)
      }
    }
    graph_client.request(UpdateWaypoint, {
      "waypoint":
        {
          'id': context.state.selectedWaypoint.id,
          'lonlat': context.state.selectedWaypoint.lonlat,
          'title': context.state.selectedWaypoint.title,
          'type': context.state.selectedWaypoint.type,
          "description": context.state.selectedWaypoint.description,
          "coordinateReferenceSystem": context.state.selectedWaypoint.coordinateReferenceSystem,
          "public": context.state.selectedWaypoint.public,
          "photos": context.state.waypointsReadyForUpload,
        }
    }).then((data: any) => {
      if (data.errors) {
        alert(data.errors[0].message)
      }
      if (data.updateWaypoint.errors && data.updateWaypoint.errors.length > 0) {
        alert(data.updateWaypoint.errors[0].message)
      }
      context.state.loading = false;
      // @ts-ignore
      $('#add-to-map-dialog').modal('hide');
      context.dispatch('addWaypointsToMap')

    }).catch((error: any) => {
      alert(error)
    })
  },
  planTrailToSelectedWaypoint(context: any) {
    const lngLat = context.getters.getPointFromSelectedWaypoint;
    const paramValue = `${lngLat.lat},${lngLat.lng}`; // replace this with the actual value you want
    const url = `${window.location.origin}/trail-planner?startPoint=${paramValue}`;
    window.open(url, "_blank");
  },
  removeWaypointImage(context: any, image: any) {
    graph_client.request(DestroyWaypointImage, {
      input: {
        waypointId: context.state.selectedWaypoint.id,
        photoId: image.id
      }
    }).then((data: any) => {
      console.log(data)
    }).catch((error: any) => {
      console.error(error)
    })
  },
  provisionallyRemoveWaypointImage(context: any, image: any) {
    const selectedWaypointPhotos = context.state.selectedWaypoint.waypointPhotos;
    const imageIndex = selectedWaypointPhotos.indexOf(image);
    const imageIndexInReadyForUpload = context.state.waypointsReadyForUpload.indexOf(image.file.url);

    if (imageIndexInReadyForUpload !== -1) {
      context.state.waypointsReadyForUpload.splice(imageIndexInReadyForUpload, 1);
    }

    // Check if the image exists in the photos array.
    if (imageIndex !== -1) {
      // Remove the image and get the removed image(s).
      const removedImages = selectedWaypointPhotos.splice(imageIndex, 1);

      // Since splice returns an array, the first item of the returned array will be your removed image.
      const removedImageId = removedImages[0];

      context.state.waypointPhotosToRemove.push(removedImageId);
    }
  },
  importGPXFile(context: any, file: any) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const gpxContent = e.target.result;
      let geoJson = gpxFileToGeoJsonHelper(gpxContent)
      let points = geoJson.features.filter((f: any) => f.geometry.type === "Point")
      if (points.length === 0) {
        return alert("No points found in GPX file")
      } else {
        const confirm = window.confirm(`Found ${points.length} points in GPX file. Do you want to create them as waypoints?`)
        if (!confirm) {
          return
        }
        const bounds = getBoundsFromGeoJsonPointsFeatureCollection(geoJson)
        console.log(bounds)
        const convertedBounds = [
          [bounds.southwest[1], bounds.southwest[0]],  // [minLon, minLat]
          [bounds.northeast[1], bounds.northeast[0]]   // [maxLon, maxLat]
        ];
        window.map.fitBounds(convertedBounds, {padding: 10})
        $('#add-to-map-dialog').modal('hide');
        context.state.loading = true
        context.dispatch('createWaypoints', {waypoints: points, title: file.name})
      }
    };
    reader.readAsText(file);
  },
  createWaypoints(context: any, payload: {waypoints: any[], title: string}) {
    const variables = {
      input: {
        input: payload.waypoints.map((w: any) => {
          return {
            type: "other",
            title: w.properties?.title ?? "Custom Waypoint",
            description: w.properties?.description ?? "",
            public: true,
            coordinateReferenceSystem: 'NIGB EPGS:4326',
            lonlat: `POINT (${w.geometry.coordinates[0]} ${w.geometry.coordinates[1]})`
          };
        })
      }
    };

    graph_client.request(CreateWaypoints, variables) // Directly send the waypointsData
      .then((data: any) => {
        context.dispatch('addWaypointsToMap')
        const waypointIds = data.createWaypoints.map((w: any) => w.id)
        context.dispatch('createWaypointGroup', {
          title: payload.title,
          waypointIds: waypointIds
        })
      }).catch((error: any) => {
      console.error(error)
      alert(`Error creating waypoints: ${error}`)
    });
  },
  showWaypointOnMap(context, waypoint: any) {
    const point = wktPointToLatLng(waypoint.lonlat)!
    window.map.flyTo({
      center: point,
      zoom: 15
    })
    const poiType = POIUtility.fromString(waypoint.type)
    const addedWaypointRandomId = addWaypointToMapHelper(poiType, waypoint.clientId, point)
    context.state.individualWaypointLayerIds.push(addedWaypointRandomId)
    const dialog = $('#waypoint-options-dialog');
    // @ts-ignore
    dialog.modal('hide');
  },
  exportSelectedWaypointGroupToGPX(context: any) {
    const geoJson = waypointsToGeoJsonFeatureCollectionHelper(context.state.selectedWaypointGroup.waypoints)
    const blob = new Blob([toGpx(geoJson)], { type: 'application/gpx+xml' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = context.state.selectedWaypointGroup?.title ? context.state.selectedWaypointGroup.title + ".gpx" : 'hiiker-waypoint-data.gpx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
  exportUserWaypointsToGPX(context: any) {
    const geoJson = waypointsToGeoJsonFeatureCollectionHelper(context.state.allUserWaypoints)
    const blob = new Blob([toGpx(geoJson)], { type: 'application/gpx+xml' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = context.state.selectedWaypointGroup?.title ? context.state.selectedWaypointGroup.title + ".gpx" : 'hiiker-waypoint-data.gpx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
  exportWaypointToGPX(context: any, waypoint) {
    const geoJson = waypointsToGeoJsonFeatureCollectionHelper([waypoint])
    const blob = new Blob([toGpx(geoJson)], { type: 'application/gpx+xml' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = waypoint.title ? waypoint.title + ".gpx" : 'hiiker-waypoint-data.gpx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
  destroySelectedWaypointGroup(context: any) {
    graph_client.request(DestroyWaypointGroup, {waypointGroup: {id: context.state.selectedWaypointGroup.id}}).then(async (data: any) => {
      console.log(data)
      await context.dispatch('getAllUserWaypointGroups')
      context.commit('popPage')
    })
  }
}

const mutations: MutationTree<WaypointDialogState> = {
  toggleWaypointAddingNotification(state: WaypointDialogState) {
    state.waypointAddingModeEnabled = !state.waypointAddingModeEnabled;
  },
  setTopPage(state: WaypointDialogState, page: WaypointOptionsDialogPages) {
    state.pageStack.push(page);
    state.pageStack = state.pageStack
  },
  resetPageStack(state: WaypointDialogState) {
    state.pageStack = [WaypointOptionsDialogPages.WaypointOptions];
  },
  clearSearchedWaypoints(state: WaypointDialogState) {
    state.searchedWaypoints = [];
  },
  popPage(state: WaypointDialogState) {
    state.pageStack.pop();
    state.pageStack = state.pageStack
  },
  setSelectedWaypointGroup(state: WaypointDialogState, waypointGroup: any) {
    state.selectedWaypointGroup = waypointGroup;
  },
  setSelectedWaypointDescription(state, description) {
    state.selectedWaypoint.description = description;
  },
  setSelectedWaypointTitle(state, title) {
    state.selectedWaypoint.title = title;
  },
  setSelectedWaypointType(state, type) {
    state.selectedWaypoint.type = type;
  },
  setSelectedWaypointPublic(state, isPublic) {
    state.selectedWaypoint.public = isPublic;
  },
}


const getters: GetterTree<WaypointDialogState, any> = {
  topPage(state) {
    return state.pageStack[state.pageStack.length - 1];
  },
  topPageIsWaypointOptions(state, getters) {
    return getters.topPage === WaypointOptionsDialogPages.WaypointOptions;
  },
  topPageIsWaypointViewDetails(state, getters) {
    return getters.topPage === WaypointOptionsDialogPages.WaypointViewDetails;
  },
  topPageIsWaypointEditDetails(state, getters) {
    return getters.topPage === WaypointOptionsDialogPages.WaypointEditDetails;
  },
  topPageIsWaypoints(state, getters) {
    return getters.topPage === WaypointOptionsDialogPages.Waypoints;
  },
  topPageIsWaypointGroups(state, getters) {
    return getters.topPage === WaypointOptionsDialogPages.WaypointGroups;
  },
  topPageIsWaypointGroupsWaypoints(state, getters) {
    return getters.topPage === WaypointOptionsDialogPages.WaypointGroupsWaypoints;
  },
  topPageIsWaypointDetails(state, getters) {
    return getters.topPage === WaypointOptionsDialogPages.WaypointDetails;
  },
  topPageIsWaypointGroupSelection(state, getters) {
    return getters.topPage === WaypointOptionsDialogPages.WaypointGroupSelection;
  },
  waypointsForList(state) {
    return state.searchedWaypoints
  },
  selectedWaypointShareUrl(state) {
    if (state.selectedWaypoint == null) {
      throw new Error("No waypoint selected");
    }
    const fullBaseUrl = window.location.protocol + '//' + window.location.host;
    return fullBaseUrl + '/map?waypoint=' + state.selectedWaypoint.clientId;
  },
  getPointFromSelectedWaypoint(state) {
    if (state.selectedWaypoint == null) {
      throw new Error("No waypoint selected");
    }
    return wktPointToLatLng(state.selectedWaypoint.lonlat);
  }
}

const state = Vue.observable(new WaypointDialogState());

export default class WaypointDialogModule implements Module<WaypointDialogState, any> {
  namespaced = true
  state = state
  actions = actions
  mutations = mutations
  getters = getters
}
