import {wktPointToLatLng} from "./WKTPointToLatLng";
import {POI, POIUtility} from "../models/POI";

export function waypointsToGeoJsonFeatureCollectionHelper(waypoints: any[]) {
  const features = waypoints.map((w) => {
    let splitPoints = wktPointToLatLng(w.lonlat)
    try {
      let poiType = POIUtility.fromString(w.type)
      return {
        "type": "Feature",
        "properties": {
          "title": w.title,
          "clientId": w.clientId,
          "iconName": poiType.getSpriteSheetWaypointIcon(),
          "type": poiType.poi,
          "isWaypoint": true,
        },
        "geometry": {
          "type": "Point",
          "coordinates": [splitPoints?.lng, splitPoints?.lat]
        }
      }
    } catch (e) {
      console.log(e + " for " + w)
      return null
    }
  })
  return {
    "type": "FeatureCollection",
    "features": features.filter((f) => f !== null)
  }
}
