import axios_client from "../helpers/axios_client";
import icon_hostel from "../../img/pois/icon_hostel.png";
import icon_hotel from "../../img/pois/icon_hotel.png";
import icon_pub from "../../img/pois/icon_pub.png";
import icon_motel from "../../img/pois/icon_motel.png";
import icon_bnb from "../../img/pois/icon_bnb.png";
import icon_peak from "../../img/pois/icon_peak.png";
import icon_shop from "../../img/pois/icon_shop.png";
import icon_town from "../../img/pois/icon_town.png";
import icon_water from "../../img/pois/icon_water.png";
import icon_post_office from "../../img/pois/icon_post_office.png";
import icon_medical from "../../img/pois/icon_medical.png";
import icon_shelter from "../../img/pois/icon_shelter.png";
import icon_history from "../../img/pois/icon_history.png";
import icon_campground from "../../img/pois/icon_campground.png";
import icon_viewpoint from "../../img/pois/icon_viewpoint.png";
import icon_pharmacy from "../../img/pois/icon_pharmacy.png";
import icon_fast_food from "../../img/pois/icon_fast_food.png";
import icon_bus from "../../img/pois/icon_bus.png";
import icon_information from "../../img/pois/icon_information.png";
import icon_cave from "../../img/pois/icon_cave.png";
import icon_coffee from "../../img/pois/icon_coffee.png";
import icon_ev_charging from "../../img/pois/icon_ev_charging.png";
import icon_gate from "../../img/pois/icon_gate.png";
import icon_junction from "../../img/pois/icon_junction.png";
import icon_parking from "../../img/pois/icon_parking.png";
import icon_picnic from "../../img/pois/icon_picnic.png";
import icon_playground from "../../img/pois/icon_playground.png";
import icon_restroom from "../../img/pois/icon_restroom.png";
import icon_storage from "../../img/pois/icon_storage.png";
import icon_swimming from "../../img/pois/icon_swimming.png";
import icon_wifi from "../../img/pois/icon_wifi.png";
import icon_wildlife from "../../img/pois/icon_wildlife.png";
import icon_waterfall from "../../img/pois/icon_waterfall.png";
import icon_warning from "../../img/pois/icon_warning.png";

import icon_hostel_waypoint from "../../img/pois/icon_hostel_waypoint.png";
import icon_hotel_waypoint from "../../img/pois/icon_hotel_waypoint.png";
import icon_pub_waypoint from "../../img/pois/icon_pub_waypoint.png";
import icon_motel_waypoint from "../../img/pois/icon_motel_waypoint.png";
import icon_bnb_waypoint from "../../img/pois/icon_bnb_waypoint.png";
import icon_peak_waypoint from "../../img/pois/icon_peak_waypoint.png";
import icon_shop_waypoint from "../../img/pois/icon_shop_waypoint.png";
import icon_town_waypoint from "../../img/pois/icon_town_waypoint.png";
import icon_water_waypoint from "../../img/pois/icon_water_waypoint.png";
import icon_post_office_waypoint from "../../img/pois/icon_post_office_waypoint.png";
import icon_medical_waypoint from "../../img/pois/icon_medical_waypoint.png";
import icon_shelter_waypoint from "../../img/pois/icon_shelter_waypoint.png";
import icon_history_waypoint from "../../img/pois/icon_history_waypoint.png";
import icon_campground_waypoint from "../../img/pois/icon_campground_waypoint.png";
import icon_viewpoint_waypoint from "../../img/pois/icon_viewpoint_waypoint.png";
import icon_pharmacy_waypoint from "../../img/pois/icon_pharmacy_waypoint.png";
import icon_fast_food_waypoint from "../../img/pois/icon_fast_food_waypoint.png";
import icon_custom_waypoint from "../../img/pois/icon_custom_waypoint.png";
import icon_bus_waypoint from "../../img/pois/icon_bus_waypoint.png";
import icon_information_waypoint from "../../img/pois/icon_information_waypoint.png";
import icon_cave_waypoint from "../../img/pois/icon_cave_waypoint.png";
import icon_coffee_waypoint from "../../img/pois/icon_coffee_waypoint.png";
import icon_ev_charging_waypoint from "../../img/pois/icon_ev_charging_waypoint.png";
import icon_gate_waypoint from "../../img/pois/icon_gate_waypoint.png";
import icon_junction_waypoint from "../../img/pois/icon_junction_waypoint.png";
import icon_parking_waypoint from "../../img/pois/icon_parking_waypoint.png";
import icon_picnic_waypoint from "../../img/pois/icon_picnic_waypoint.png";
import icon_playground_waypoint from "../../img/pois/icon_playground_waypoint.png";
import icon_restroom_waypoint from "../../img/pois/icon_restroom_waypoint.png";
import icon_storage_waypoint from "../../img/pois/icon_storage_waypoint.png";
import icon_swimming_waypoint from "../../img/pois/icon_swimming_waypoint.png";
import icon_wifi_waypoint from "../../img/pois/icon_wifi_waypoint.png";
import icon_wildlife_waypoint from "../../img/pois/icon_wildlife_waypoint.png";
import icon_waterfall_waypoint from "../../img/pois/icon_waterfall_waypoint.png";
import icon_warning_waypoint from "../../img/pois/icon_warning_waypoint.png";

import graph_client from "../graphql/graph_client";
import { GetAccomsByPolygon } from "../graphql/poi-graph";

import highlight_bnb from "../../img/highlights/highlight-bnb.webp";
import highlight_campground from "../../img/highlights/highlight-campsite.webp";
import highlight_history from "../../img/highlights/highlight-history.webp";
import highlight_hotel from "../../img/highlights/highlight-hotel.webp";
import highlight_hostel from "../../img/highlights/highlight-hostel.webp";
import highlight_peak from "../../img/highlights/highlight-peak.webp";
import highlight_restaurant from "../../img/highlights/highlight-restaurant.webp";
import highlight_town from "../../img/highlights/highlight-town.webp";
import highlight_shelter from "../../img/highlights/highlight-shelter.webp";
import highlight_pub from "../../img/highlights/highlight-pub.webp";
import highlight_viewpoint from "../../img/highlights/highlight-view.webp";
import highlight_bus_stop from "../../img/highlights/highlight-busstop.webp";
import highlight_transport from "../../img/highlights/highlight-transport.webp";
import highlight_shop from "../../img/highlights/highlight-shop.webp";
import highlight_water from "../../img/highlights/highlight-water.webp";
import highlight_pharmacy from "../../img/highlights/highlight-pharmacy.webp";
import highlight_healthcare from "../../img/highlights/highlight-healthcare.webp";
import highlight_postOffice from "../../img/highlights/highlight-postoffice.webp";
import highlight_fast_food from "../../img/highlights/highlight-fastfood.webp";
import highlight_osmCampsite from "../../img/highlights/highlight-osmcampsite.webp";

import placeholder_bnb from "../../img/highlights/placeholder-bnb.webp";
import placeholder_campground from "../../img/highlights/placeholder-campground.webp";
import placeholder_osmCampsite from "../../img/highlights/placeholder-osmCampsite.webp";
import placeholder_history from "../../img/highlights/placeholder-history.webp";
import placeholder_hotel from "../../img/highlights/placeholder-hotel.webp";
import placeholder_hostel from "../../img/highlights/placeholder-hostel.webp";
import placeholder_peak from "../../img/highlights/placeholder-peaks.webp";
import placeholder_restaurant from "../../img/highlights/placeholder-restaurant.webp";
import placeholder_town from "../../img/highlights/placeholder-town.webp";
import placeholder_shelter from "../../img/highlights/placeholder-shelter.webp";
import placeholder_pub from "../../img/highlights/placeholder-pub.webp";
import placeholder_viewpoint from "../../img/highlights/placeholder-viewpoint.webp";
import placeholder_transport from "../../img/highlights/placeholder-transport.webp";
import placeholder_shop from "../../img/highlights/placeholder-shops.webp";
import placeholder_water from "../../img/highlights/placeholder-water.webp";
import placeholder_pharmacy from "../../img/highlights/placeholder-pharmacy.webp";
import placeholder_healthcare from "../../img/highlights/placeholder-healthcare.webp";
import placeholder_bus_stop from "../../img/highlights/placeholder-busStops.webp";
import placeholder_fast_food from "../../img/highlights/placeholder-fastfood.webp";
import placeholder_postOffice from "../../img/highlights/placeholder-postOffice.webp";

export enum POI {
    Campground = 'campground',
    Hotel = 'hotel',
    Hostel = 'hostel',
    BnB = 'bnb',
    Motel = 'motel',

    OSMCampsites = 'osmCampsite',
    Shelter = 'shelter',
    Pub = 'pub',
    FastFood = 'fastfood',
    Viewpoint = 'viewpoint',
    TransportStation = 'transportStations',
    Shop = 'shop',
    Town = 'town',
    History = 'history',
    BusStop = 'busStops',
    PostOffice = 'postOffice',
    Peak = 'peaks',
    Pharmacy = 'pharmacy',
    Water = 'water',
    Healthcare = 'healthcare',
    Restaurant = 'restaurant',
    Other = 'other',
    Cave = 'cave',
    Coffee = 'coffee',
    EVCharging = 'evCharging',
    Gate = 'gate',
    Information = 'information',
    Junction = 'junction',
    Parking = 'parking',
    Picnic = 'picnic',
    Playground = 'playground',
    Restroom = 'restroom',
    Storage = 'storage',
    Swimming = 'swimming',
    Wifi = 'wifi',
    Wildlife = 'wildlife',
    Waterfall = 'waterfall',
    Warning = 'warning'
}

export class POIUtility {
    poi: POI;
    layerName: string;
    mvtUrl: string;
    puckIconName: string;

    constructor(poi: POI) {
        this.poi = poi;
        this.layerName = poi + 'Layer-234';
        // Compute and set the mvtUrl property upon initialization.
        this.mvtUrl = this.computeMvtUrl();
        this.puckIconName = this.computePuckIconName();
    }

    prettyName(): string {
        switch (this.poi) {
            case POI.Campground:
                return 'Campground';
            case POI.Hotel:
                return 'Hotel';
            case POI.Hostel:
                return 'Hostel';
            case POI.BnB:
                return 'BnB';
            case POI.Motel:
                return 'Motel';
            case POI.OSMCampsites:
                return 'OSM Campsites';
            case POI.Shelter:
                return 'Shelter';
            case POI.Pub:
                return 'Pub';
            case POI.FastFood:
                return 'Fast Food';
            case POI.Viewpoint:
                return 'Viewpoint';
            case POI.TransportStation:
                return 'Transport Station';
            case POI.Shop:
                return 'Shop';
            case POI.Town:
                return 'Town';
            case POI.History:
                return 'History';
            case POI.BusStop:
                return 'Bus Stop';
            case POI.PostOffice:
                return 'Post Office';
            case POI.Peak:
                return 'Peak';
            case POI.Pharmacy:
                return 'Pharmacy';
            case POI.Water:
                return 'Water Source';
            case POI.Healthcare:
                return 'Healthcare';
            case POI.Restaurant:
                return 'Restaurant';
            case POI.Other:
                return 'Custom Waypoint';
            case POI.Cave:
                return 'Cave';
            case POI.Coffee:
                return 'Coffee';
            case POI.EVCharging:
                return 'EV Charging';
            case POI.Gate:
                return 'Gate';
            case POI.Information:
                return 'Information';
            case POI.Junction:
                return 'Junction';
            case POI.Parking:
                return 'Parking';
            case POI.Picnic:
                return 'Picnic';
            case POI.Playground:
                return 'Playground';
            case POI.Restroom:
                return 'Restroom';
            case POI.Storage:
                return 'Storage';
            case POI.Swimming:
                return 'Swimming';
            case POI.Wifi:
                return 'Wifi';
            case POI.Wildlife:
                return 'Wildlife';
            case POI.Waterfall:
                return 'Waterfall';
            case POI.Warning:
                return 'Warning';
            default:
                throw Error('Invalid POI');
        }
    }

    imageLayerName(): string {
        return this.poi + 'Layer-234';
    }

    serverType(): string {
        switch (this.poi) {
            case POI.Campground:
                return 'campground';
            case POI.Hotel:
                return 'hotel';
            case POI.Hostel:
                return 'hostel';
            case POI.BnB:
                return 'bnb';
            case POI.Motel:
                return 'motel';
            case POI.OSMCampsites:
                return 'osmcampsite';
            case POI.Shelter:
                return 'shelter';
            case POI.Pub:
                return 'pub';
            case POI.FastFood:
                return 'fastfood';
            case POI.Viewpoint:
                return 'viewpoint';
            case POI.TransportStation:
                return 'transport_station';
            case POI.Shop:
                return 'shop';
            case POI.Town:
                return 'town';
            case POI.History:
                return 'history';
            case POI.BusStop:
                return 'bus_stop';
            case POI.PostOffice:
                return 'post_office';
            case POI.Peak:
                return 'peak';
            case POI.Pharmacy:
                return 'pharmacy';
            case POI.Water:
                return 'water';
            case POI.Healthcare:
                return 'healthcare';
            case POI.Restaurant:
                return 'restaurant';
            case POI.Cave:
                return 'cave';
            case POI.Coffee:
                return 'coffee';
            case POI.EVCharging:
                return 'ev_charging';
            case POI.Gate:
                return 'gate';
            case POI.Information:
                return 'information';
            case POI.Junction:
                return 'junction';
            case POI.Parking:
                return 'parking';
            case POI.Picnic:
                return 'picnic';
            case POI.Playground:
                return 'playground';
            case POI.Restroom:
                return 'restroom';
            case POI.Storage:
                return 'storage';
            case POI.Swimming:
                return 'swimming';
            case POI.Wifi:
                return 'wifi';
            case POI.Wildlife:
                return 'wildlife';
            case POI.Waterfall:
                return 'waterfall';
            case POI.Warning:
                return 'warning';
            case POI.Other:
                return 'other';
            default:
                throw Error('Invalid POI');
        }
    }

    /**
     * Returns the relative path for the MVT based on whether the POI is an accommodation or an amenity.
     */
    mvtPath() {
        if (this.isAccommodation()) {
            return 'get_accommodations_mvt';
        } else {
            return 'get_amenities_mvt';
        }
    }

    /**
     * Computes the full MVT URL.
     */
    computeMvtUrl(): string {
        return `${window.location.origin}/vector/${this.mvtPath()}/{z}/{x}/{y}?type=${this.serverType()}`;
    }

    computePuckIconName(): string {
        return `puck_icon_${this.serverType()}`;
    }

    previewImage(): string {
        switch (this.poi) {
            case POI.Campground:
                return placeholder_campground;
            case POI.Hotel:
                return placeholder_hotel;
            case POI.Hostel:
                return placeholder_hostel;
            case POI.BnB:
                return placeholder_bnb;
            case POI.Motel:
                return placeholder_bnb;
            case POI.OSMCampsites:
                return placeholder_osmCampsite;
            case POI.Shelter:
                return placeholder_shelter;
            case POI.Pub:
                return placeholder_pub;
            case POI.FastFood:
                return placeholder_fast_food;
            case POI.Viewpoint:
                return placeholder_viewpoint;
            case POI.TransportStation:
                return placeholder_transport;
            case POI.Shop:
                return placeholder_shop;
            case POI.Town:
                return placeholder_town;
            case POI.History:
                return placeholder_history;
            case POI.BusStop:
                return placeholder_bus_stop;
            case POI.PostOffice:
                return placeholder_postOffice;
            case POI.Peak:
                return placeholder_peak;
            case POI.Pharmacy:
                return placeholder_pharmacy;
            case POI.Water:
                return placeholder_water;
            case POI.Healthcare:
                return placeholder_healthcare;
            case POI.Restaurant:
                return placeholder_restaurant;
            default:
                return highlight_town;
        }
    }

    highlightImage() {
        switch (this.poi) {
            case POI.Campground:
                return highlight_campground;
            case POI.Hotel:
                return highlight_hotel;
            case POI.Hostel:
                return highlight_hostel;
            case POI.BnB:
                return highlight_bnb;
            case POI.Motel:
                return highlight_hotel;
            case POI.OSMCampsites:
                return highlight_osmCampsite;
            case POI.Shelter:
                return highlight_shelter;
            case POI.Pub:
                return highlight_pub;
            case POI.FastFood:
                return highlight_fast_food;
            case POI.Viewpoint:
                return highlight_viewpoint;
            case POI.TransportStation:
                return highlight_transport;
            case POI.Shop:
                return highlight_shop;
            case POI.Town:
                return highlight_town;
            case POI.History:
                return highlight_history;
            case POI.BusStop:
                return highlight_bus_stop;
            case POI.PostOffice:
                return highlight_postOffice;
            case POI.Peak:
                return highlight_peak;
            case POI.Pharmacy:
                return highlight_pharmacy;
            case POI.Water:
                return highlight_water;
            case POI.Healthcare:
                return highlight_healthcare;
            case POI.Restaurant:
                return highlight_restaurant;
            default:
                return highlight_town;
        }
    }

    private getCategory() {
        switch (this.poi) {
            case POI.Campground:
            case POI.Hotel:
            case POI.Hostel:
            case POI.BnB:
            case POI.Motel:
                return 'accommodations';
            default:
                return 'amenities';
        }
    }

    databaseClassName() {
        switch (this.poi) {
            case POI.Campground:
            case POI.Hotel:
            case POI.Hostel:
            case POI.BnB:
            case POI.Motel:
                return 'Accommodation';
            default:
                return 'Amenity';
        }
    }

    get(trailUrl: string): Promise<any> {
        return axios_client.get(trailUrl + `/${this.getCategory()}.json?type=${this.poi}`);
    }

    getColor() {
        switch (this.poi) {
            case POI.Hotel:
                return "#E45D5D";
            case POI.Hostel:
                return "#A14DAF";
            case POI.BnB:
                return "#F57700";
            case POI.Campground:
                return "#70C381";
            case POI.Motel:
                return "#BAD033";
            case POI.BusStop:
                return "#2B87CA";
            case POI.FastFood:
                return "#FFC107";
            case POI.Healthcare:
                return "#E45D5D";
            case POI.OSMCampsites:
                return "#2CC2E3";
            case POI.Peak:
                return "#BAD033";
            case POI.Pharmacy:
                return "#BAD033";
            case POI.Pub:
                return "#2B87CA";
            case POI.Restaurant:
                return "#FFC107";
            case POI.Shelter:
                return "#FFC107";
            case POI.Shop:
                return "#70C381";
            case POI.Town:
                return "#A14DAF";
            case POI.TransportStation:
                return "#2B87CA";
            case POI.Viewpoint:
                return "#2CC2E3";
            case POI.Water:
                return "#2CC2E3";
            case POI.History:
                return "#F57700";
            case POI.PostOffice:
                return "#F57700";
            case POI.Cave:
                return "#A14DAF";
            case POI.Coffee:
                return "#70C381";
            case POI.EVCharging:
                return "#FDDF00";
            case POI.Gate:
                return "#A14DAF";
            case POI.Information:
                return "#FDDF00";
            case POI.Junction:
                return "#2CC2E3";
            case POI.Parking:
                return "#2B87CA";
            case POI.Picnic:
                return "#F57700";
            case POI.Playground:
                return "#A14DAF";
            case POI.Restroom:
                return "#E45D5D";
            case POI.Storage:
                return "#E45D5D";
            case POI.Swimming:
                return "#2CC2E3";
            case POI.Wifi:
                return "#70C381";
            case POI.Wildlife:
                return "#F57700";
            case POI.Waterfall:
                return "#2B87CA";
            case POI.Warning:
                return "#E45D5D";
        }
    }

    getPublicImageIcon() {
        switch (this.poi) {
            case POI.Campground:
                return icon_campground;
            case POI.Hotel:
                return icon_hotel;
            case POI.Hostel:
                return icon_hostel;
            case POI.BnB:
                return icon_bnb;
            case POI.Motel:
                return icon_motel;
            case POI.OSMCampsites:
                return icon_campground;
            case POI.Shelter:
                return icon_shelter;
            case POI.Pub:
                return icon_pub;
            case POI.FastFood:
                return icon_fast_food;
            case POI.Viewpoint:
                return icon_viewpoint;
            case POI.TransportStation:
                return icon_bus;
            case POI.Shop:
                return icon_shop;
            case POI.Town:
                return icon_town;
            case POI.History:
                return icon_history;
            case POI.BusStop:
                return icon_bus;
            case POI.PostOffice:
                return icon_post_office;
            case POI.Peak:
                return icon_peak;
            case POI.Pharmacy:
                return icon_pharmacy;
            case POI.Water:
                return icon_water;
            case POI.Healthcare:
                return icon_medical;
            case POI.Restaurant:
                return icon_fast_food;
            case POI.Other:
                return icon_information;
            case POI.Cave:
                return icon_cave;
            case POI.Coffee:
                return icon_coffee;
            case POI.EVCharging:
                return icon_ev_charging;
            case POI.Gate:
                return icon_gate;
            case POI.Information:
                return icon_information;
            case POI.Junction:
                return icon_junction;
            case POI.Parking:
                return icon_parking;
            case POI.Picnic:
                return icon_picnic;
            case POI.Playground:
                return icon_playground;
            case POI.Restroom:
                return icon_restroom;
            case POI.Storage:
                return icon_storage;
            case POI.Swimming:
                return icon_swimming;
            case POI.Wifi:
                return icon_wifi;
            case POI.Wildlife:
                return icon_wildlife;
            case POI.Waterfall:
                return icon_waterfall;
            case POI.Warning:
                return icon_warning;
            default:
                throw Error('Invalid POI');
        }
    }

    getSpriteSheetIcon() {
        switch (this.poi) {
            case POI.Campground:
                return 'poi_icon_campground';
            case POI.Hotel:
                return 'poi_icon_hotel';
            case POI.Hostel:
                return 'poi_icon_hostel';
            case POI.BnB:
                return 'poi_icon_bnb';
            case POI.Motel:
                return 'poi_icon_motel';
            case POI.OSMCampsites:
                return 'poi_icon_campground';
            case POI.Shelter:
                return 'poi_icon_shelter';
            case POI.Pub:
                return 'poi_icon_pub';
            case POI.FastFood:
                return 'poi_icon_fast_food';
            case POI.Viewpoint:
                return 'poi_icon_viewpoint';
            case POI.TransportStation:
                return 'poi_icon_bus';
            case POI.Shop:
                return 'poi_icon_shop';
            case POI.Town:
                return 'poi_icon_town';
            case POI.History:
                return 'poi_icon_history';
            case POI.PostOffice:
                return 'poi_icon_post_office';
            case POI.BusStop:
                return 'poi_icon_bus';
            case POI.Peak:
                return 'poi_icon_peak';
            case POI.Pharmacy:
                return 'poi_icon_pharmacy';
            case POI.Water:
                return 'poi_icon_water';
            case POI.Healthcare:
                return 'poi_icon_medical';
            case POI.Restaurant:
                return 'poi_icon_fast_food';
            case POI.Other:
                return 'poi_icon_custom';
            case POI.Cave:
                return 'poi_icon_cave';
            case POI.Coffee:
                return 'poi_icon_coffee';
            case POI.EVCharging:
                return 'poi_icon_ev_charging';
            case POI.Gate:
                return 'poi_icon_gate';
            case POI.Information:
                return 'poi_icon_information';
            case POI.Junction:
                return 'poi_icon_junction';
            case POI.Parking:
                return 'poi_icon_parking';
            case POI.Picnic:
                return 'poi_icon_picnic';
            case POI.Playground:
                return 'poi_icon_playground';
            case POI.Restroom:
                return 'poi_icon_restroom';
            case POI.Storage:
                return 'poi_icon_storage';
            case POI.Swimming:
                return 'poi_icon_swimming';
            case POI.Wifi:
                return 'poi_icon_wifi';
            case POI.Wildlife:
                return 'poi_icon_wildlife';
            case POI.Waterfall:
                return 'poi_icon_waterfall';
            case POI.Warning:
                return 'poi_icon_warning';
            default:
                throw Error('Invalid POI');
        }
    }

    getSpriteSheetWaypointIcon() {
        switch (this.poi) {
            case POI.Campground:
                return 'icon_campground_waypoint';
            case POI.Hotel:
                return 'icon_hotel_waypoint';
            case POI.Hostel:
                return 'icon_hostel_waypoint';
            case POI.BnB:
                return 'icon_bnb_waypoint';
            case POI.Motel:
                return 'icon_motel_waypoint';
            case POI.OSMCampsites:
                return 'icon_campground_waypoint';
            case POI.Shelter:
                return 'icon_shelter_waypoint';
            case POI.Pub:
                return 'icon_pub_waypoint';
            case POI.FastFood:
                return 'icon_fast_food_waypoint';
            case POI.Viewpoint:
                return 'icon_viewpoint_waypoint';
            case POI.TransportStation:
                return 'icon_bus_waypoint';
            case POI.Shop:
                return 'icon_shop_waypoint';
            case POI.Town:
                return 'icon_town_waypoint';
            case POI.History:
                return 'icon_history_waypoint';
            case POI.BusStop:
                return 'icon_bus_waypoint';
            case POI.PostOffice:
                return 'icon_post_office_waypoint';
            case POI.Peak:
                return 'icon_peak_waypoint';
            case POI.Pharmacy:
                return 'icon_pharmacy_waypoint';
            case POI.Water:
                return 'icon_water_waypoint';
            case POI.Healthcare:
                return 'icon_medical_waypoint';
            case POI.Restaurant:
                return 'icon_fast_food_waypoint';
            case POI.Other:
                return 'icon_custom_waypoint';
            case POI.Cave:
                return 'icon_cave_waypoint';
            case POI.Coffee:
                return 'icon_coffee_waypoint';
            case POI.EVCharging:
                return 'icon_ev_charging_waypoint';
            case POI.Gate:
                return 'icon_gate_waypoint';
            case POI.Information:
                return 'icon_information_waypoint';
            case POI.Junction:
                return 'icon_junction_waypoint';
            case POI.Parking:
                return 'icon_parking_waypoint';
            case POI.Picnic:
                return 'icon_picnic_waypoint';
            case POI.Playground:
                return 'icon_playground_waypoint';
            case POI.Restroom:
                return 'icon_restroom_waypoint';
            case POI.Storage:
                return 'icon_storage_waypoint';
            case POI.Swimming:
                return 'icon_swimming_waypoint';
            case POI.Wifi:
                return 'icon_wifi_waypoint';
            case POI.Wildlife:
                return 'icon_wildlife_waypoint';
            case POI.Waterfall:
                return 'icon_waterfall_waypoint';
            case POI.Warning:
                return 'icon_warning_waypoint';
            default:
                throw Error('Invalid POI');
        }
    }

    getPublicWaypointIcon() {
        switch (this.poi) {
            case POI.Campground:
                return icon_campground_waypoint;
            case POI.Hotel:
                return icon_hotel_waypoint;
            case POI.Hostel:
                return icon_hostel_waypoint;
            case POI.BnB:
                return icon_bnb_waypoint;
            case POI.Motel:
                return icon_motel_waypoint;
            case POI.OSMCampsites:
                return icon_campground_waypoint;
            case POI.Shelter:
                return icon_shelter_waypoint;
            case POI.Pub:
                return icon_pub_waypoint;
            case POI.FastFood:
                return icon_fast_food_waypoint;
            case POI.Viewpoint:
                return icon_viewpoint_waypoint;
            case POI.TransportStation:
                return icon_bus_waypoint;
            case POI.Shop:
                return icon_shop_waypoint;
            case POI.Town:
                return icon_town_waypoint;
            case POI.History:
                return icon_history_waypoint;
            case POI.BusStop:
                return icon_bus_waypoint;
            case POI.PostOffice:
                return icon_post_office_waypoint;
            case POI.Peak:
                return icon_peak_waypoint;
            case POI.Pharmacy:
                return icon_pharmacy_waypoint;
            case POI.Water:
                return icon_water_waypoint;
            case POI.Healthcare:
                return icon_medical_waypoint;
            case POI.Restaurant:
                return icon_fast_food_waypoint;
            case POI.Other:
                return icon_custom_waypoint;
            case POI.Cave:
                return icon_cave_waypoint;
            case POI.Coffee:
                return icon_coffee_waypoint;
            case POI.EVCharging:
                return icon_ev_charging_waypoint;
            case POI.Gate:
                return icon_gate_waypoint;
            case POI.Information:
                return icon_information_waypoint;
            case POI.Junction:
                return icon_junction_waypoint;
            case POI.Parking:
                return icon_parking_waypoint;
            case POI.Picnic:
                return icon_picnic_waypoint;
            case POI.Playground:
                return icon_playground_waypoint;
            case POI.Restroom:
                return icon_restroom_waypoint;
            case POI.Storage:
                return icon_storage_waypoint;
            case POI.Swimming:
                return icon_swimming_waypoint;
            case POI.Wifi:
                return icon_wifi_waypoint;
            case POI.Wildlife:
                return icon_wildlife_waypoint;
            case POI.Waterfall:
                return icon_waterfall_waypoint;
            case POI.Warning:
                return icon_warning_waypoint;
            default:
                throw Error('Invalid POI');
        }
    }

    static accoms(trail: any | null): POIUtility[] {
        if (trail != null) {
            const poiToReturn: POIUtility[] = [];
            if (trail.campgroundCount > 0) {
                poiToReturn.push(new POIUtility(POI.Campground));
            }
            if (trail.hotelCount > 0) {
                poiToReturn.push(new POIUtility(POI.Hotel));
            }
            if (trail.hostelCount > 0) {
                poiToReturn.push(new POIUtility(POI.Hostel));
            }
            if (trail.bnbCount > 0) {
                poiToReturn.push(new POIUtility(POI.BnB));
            }
            if (trail.motelCount > 0) {
                poiToReturn.push(new POIUtility(POI.Motel));
            }
            return poiToReturn;
        }
        return [
            new POIUtility(POI.Campground),
            new POIUtility(POI.Hotel),
            new POIUtility(POI.Hostel),
            new POIUtility(POI.BnB),
            new POIUtility(POI.Motel),
        ];
    }

    static amenities(trail: any | null): POIUtility[] {
        if (trail != null) {
            const poiToReturn: POIUtility[] = [];
            if (trail.osmcampsiteCount > 0) {
                poiToReturn.push(new POIUtility(POI.OSMCampsites));
            }
            if (trail.shelterCount > 0) {
                poiToReturn.push(new POIUtility(POI.Shelter));
            }
            if (trail.pubCount > 0) {
                poiToReturn.push(new POIUtility(POI.Pub));
            }
            if (trail.fastfoodCount > 0) {
                poiToReturn.push(new POIUtility(POI.FastFood));
            }
            if (trail.viewpointCount > 0) {
                poiToReturn.push(new POIUtility(POI.Viewpoint));
            }
            if (trail.transportStationCount > 0) {
                poiToReturn.push(new POIUtility(POI.TransportStation));
            }
            if (trail.shopCount > 0) {
                poiToReturn.push(new POIUtility(POI.Shop));
            }
            if (trail.townCount > 0) {
                poiToReturn.push(new POIUtility(POI.Town));
            }
            if (trail.historyCount > 0) {
                poiToReturn.push(new POIUtility(POI.History));
            }
            if (trail.busStopCount > 0) {
                poiToReturn.push(new POIUtility(POI.BusStop));
            }
            if (trail.postOfficeCount > 0) {
                poiToReturn.push(new POIUtility(POI.PostOffice));
            }
            if (trail.peakCount > 0) {
                poiToReturn.push(new POIUtility(POI.Peak));
            }
            if (trail.pharmacyCount > 0) {
                poiToReturn.push(new POIUtility(POI.Pharmacy));
            }
            if (trail.waterCount > 0) {
                poiToReturn.push(new POIUtility(POI.Water));
            }
            if (trail.healthcareCount > 0) {
                poiToReturn.push(new POIUtility(POI.Healthcare));
            }
            if (trail.restaurantCount > 0) {
                poiToReturn.push(new POIUtility(POI.Restaurant));
            }
            if (trail.caveCount > 0) {
                poiToReturn.push(new POIUtility(POI.Cave));
            }
            if (trail.coffeeCount > 0) {
                poiToReturn.push(new POIUtility(POI.Coffee));
            }
            if (trail.evChargingCount > 0) {
                poiToReturn.push(new POIUtility(POI.EVCharging));
            }
            if (trail.gateCount > 0) {
                poiToReturn.push(new POIUtility(POI.Gate));
            }
            if (trail.informationCount > 0) {
                poiToReturn.push(new POIUtility(POI.Information));
            }
            if (trail.junctionCount > 0) {
                poiToReturn.push(new POIUtility(POI.Junction));
            }
            if (trail.parkingCount > 0) {
                poiToReturn.push(new POIUtility(POI.Parking));
            }
            if (trail.picnicCount > 0) {
                poiToReturn.push(new POIUtility(POI.Picnic));
            }
            if (trail.playgroundCount > 0) {
                poiToReturn.push(new POIUtility(POI.Playground));
            }
            if (trail.restroomCount > 0) {
                poiToReturn.push(new POIUtility(POI.Restroom));
            }
            if (trail.storageCount > 0) {
                poiToReturn.push(new POIUtility(POI.Storage));
            }
            if (trail.swimmingCount > 0) {
                poiToReturn.push(new POIUtility(POI.Swimming));
            }
            if (trail.wifiCount > 0) {
                poiToReturn.push(new POIUtility(POI.Wifi));
            }
            if (trail.wildlifeCount > 0) {
                poiToReturn.push(new POIUtility(POI.Wildlife));
            }
            if (trail.waterfallCount > 0) {
                poiToReturn.push(new POIUtility(POI.Waterfall));
            }
            if (trail.warningCount > 0) {
                poiToReturn.push(new POIUtility(POI.Warning));
            }
            return poiToReturn;
        }
        return [
            new POIUtility(POI.OSMCampsites),
            new POIUtility(POI.Shelter),
            new POIUtility(POI.Pub),
            new POIUtility(POI.FastFood),
            new POIUtility(POI.Viewpoint),
            new POIUtility(POI.TransportStation),
            new POIUtility(POI.Shop),
            new POIUtility(POI.Town),
            new POIUtility(POI.History),
            new POIUtility(POI.BusStop),
            new POIUtility(POI.PostOffice),
            new POIUtility(POI.Peak),
            new POIUtility(POI.Pharmacy),
            new POIUtility(POI.Water),
            new POIUtility(POI.Healthcare),
            new POIUtility(POI.Restaurant),
            new POIUtility(POI.Cave),
            new POIUtility(POI.Coffee),
            new POIUtility(POI.EVCharging),
            new POIUtility(POI.Gate),
            new POIUtility(POI.Information),
            new POIUtility(POI.Junction),
            new POIUtility(POI.Parking),
            new POIUtility(POI.Picnic),
            new POIUtility(POI.Playground),
            new POIUtility(POI.Restroom),
            new POIUtility(POI.Storage),
            new POIUtility(POI.Swimming),
            new POIUtility(POI.Wifi),
            new POIUtility(POI.Wildlife),
            new POIUtility(POI.Waterfall),
            new POIUtility(POI.Warning)
        ];
    }

    static allPOIUtils(): POIUtility[] {
        return [
            new POIUtility(POI.Campground),
            new POIUtility(POI.Hotel),
            new POIUtility(POI.Hostel),
            new POIUtility(POI.BnB),
            new POIUtility(POI.Motel),
            new POIUtility(POI.OSMCampsites),
            new POIUtility(POI.Shelter),
            new POIUtility(POI.Pub),
            new POIUtility(POI.FastFood),
            new POIUtility(POI.Viewpoint),
            new POIUtility(POI.TransportStation),
            new POIUtility(POI.Shop),
            new POIUtility(POI.Town),
            new POIUtility(POI.History),
            new POIUtility(POI.BusStop),
            new POIUtility(POI.PostOffice),
            new POIUtility(POI.Peak),
            new POIUtility(POI.Pharmacy),
            new POIUtility(POI.Water),
            new POIUtility(POI.Healthcare),
            new POIUtility(POI.Restaurant),
            new POIUtility(POI.Other),
            new POIUtility(POI.Cave),
            new POIUtility(POI.Coffee),
            new POIUtility(POI.EVCharging),
            new POIUtility(POI.Gate),
            new POIUtility(POI.Information),
            new POIUtility(POI.Junction),
            new POIUtility(POI.Parking),
            new POIUtility(POI.Picnic),
            new POIUtility(POI.Playground),
            new POIUtility(POI.Restroom),
            new POIUtility(POI.Storage),
            new POIUtility(POI.Swimming),
            new POIUtility(POI.Wifi),
            new POIUtility(POI.Wildlife),
            new POIUtility(POI.Waterfall),
            new POIUtility(POI.Warning)
        ];
    }

    static checkForPassedPOI(): POIUtility | null {
        const urlParams = new URLSearchParams(window.location.search);
        const accommodation = urlParams.get('accommodations');
        if (accommodation) {
            return POIUtility.fromString(accommodation);
        }
        const amenity = urlParams.get('amenities');
        if (amenity) {
            return POIUtility.fromString(amenity);
        }
        return null;
    }

    /**
     * Converts a string into a POIUtility instance.
     * Note: The mvtUrl property is set in the constructor.
     */
    static fromString(poi: string): POIUtility {
        switch (poi) {
            case 'campground':
                return new POIUtility(POI.Campground);
            case 'hotel':
                return new POIUtility(POI.Hotel);
            case 'hostel':
                return new POIUtility(POI.Hostel);
            case 'bnb':
                return new POIUtility(POI.BnB);
            case 'motel':
                return new POIUtility(POI.Motel);
            case 'osmcampsite':
            case 'osmCampsite':
                return new POIUtility(POI.OSMCampsites);
            case 'shelter':
                return new POIUtility(POI.Shelter);
            case 'pub':
                return new POIUtility(POI.Pub);
            case 'fast-food':
            case 'fastfood':
                return new POIUtility(POI.FastFood);
            case 'viewpoint':
                return new POIUtility(POI.Viewpoint);
            case 'transport-station':
            case 'transport_station':
            case 'transportStations':
                return new POIUtility(POI.TransportStation);
            case 'shop':
                return new POIUtility(POI.Shop);
            case 'town':
                return new POIUtility(POI.Town);
            case 'history':
                return new POIUtility(POI.History);
            case 'bus-stop':
            case 'bus_stop':
            case 'busStops':
                return new POIUtility(POI.BusStop);
            case 'post-office':
            case 'post_office':
            case 'postOffice':
                return new POIUtility(POI.PostOffice);
            case 'peak':
            case 'peaks':
                return new POIUtility(POI.Peak);
            case 'pharmacy':
                return new POIUtility(POI.Pharmacy);
            case 'water':
                return new POIUtility(POI.Water);
            case 'healthcare':
                return new POIUtility(POI.Healthcare);
            case 'restaurant':
                return new POIUtility(POI.Restaurant);
            case 'other':
                return new POIUtility(POI.Other);
            case 'cave':
                return new POIUtility(POI.Cave);
            case 'coffee':
                return new POIUtility(POI.Coffee);
            case 'ev-charging':
            case 'evCharging':
            case 'ev_charging':
                return new POIUtility(POI.EVCharging);
            case 'gate':
                return new POIUtility(POI.Gate);
            case 'information':
                return new POIUtility(POI.Information);
            case 'junction':
                return new POIUtility(POI.Junction);
            case 'parking':
                return new POIUtility(POI.Parking);
            case 'picnic':
                return new POIUtility(POI.Picnic);
            case 'playground':
                return new POIUtility(POI.Playground);
            case 'restroom':
                return new POIUtility(POI.Restroom);
            case 'storage':
                return new POIUtility(POI.Storage);
            case 'swimming':
                return new POIUtility(POI.Swimming);
            case 'wifi':
                return new POIUtility(POI.Wifi);
            case 'wildlife':
                return new POIUtility(POI.Wildlife);
            case 'waterfall':
                return new POIUtility(POI.Waterfall);
            case 'warning':
                return new POIUtility(POI.Warning);
        }
        const poiUtility = POIUtility.allPOIUtils().find(
            (poiToCheck) => poiToCheck.prettyName() === poi
        );
        if (poiUtility) {
            return poiUtility;
        }
        throw Error('Invalid POI of type ' + poi);
    }

    isAccommodation(): boolean {
        return this.getCategory() === 'accommodations';
    }

    isAmenity(): boolean {
        return this.getCategory() === 'amenities';
    }
}
