/**
 * Removes all query parameters from a given URL and returns the cleansed URL.
 *
 * @function
 * @param {string} href - The full URL to be cleansed.
 * @returns {void}
 *
 * @example
 * const originalUrl = "https://example.com/page?param1=value1&param2=value2";
 * const cleansedUrl = cleanseUrl(originalUrl);
 * console.log(cleansedUrl); // Outputs: "https://example.com/page"
 *
 * @note
 * The function uses the `URL` object to parse and cleanse the provided URL.
 */
export function cleanseUrl(href: string) {
  let parsedUrl = new URL(href);
  parsedUrl.search = "";
  updateAddressBar(parsedUrl.toString())
}

// To update the actual address bar:
function updateAddressBar(url: string) {
  // @ts-ignore
  history.pushState(null, null, url);
}
