export function createWaypointMapLayerHelper(id: string) {
  return {
    'id': id,
    'type': 'symbol',
    'source': id,
    'layout': {
      'icon-image': ['get', 'iconName'],
      'icon-allow-overlap': true,
      'icon-size': 0.70,
      'icon-anchor': 'bottom',
      "icon-offset": [0, -17]
    },
  }
}
