import {POI} from "../models/POI";

export function createNewWaypointJsonHelper(lng: number, lat: number, title = "Custom Waypoint", type = POI.Other, description = "") {
  return {
    'lonlat': `POINT(${lng} ${lat})`,
    'title': title,
    'type': type,
    "description": description,
    "coordinateReferenceSystem": "EPSG:4326",
    "public": true
  }
}
