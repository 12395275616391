import tj from '@mapbox/togeojson';

export function gpxFileToGeoJsonHelper(gpxContent) {
  // Parse the GPX content to XML
  const parser = new DOMParser();
  const xml = parser.parseFromString(gpxContent, 'text/xml');

  // Convert XML to GeoJSON using togeojson
  const geojson = tj.gpx(xml);

  // Now you can use the geojson object
  return geojson;
}
