export function getBoundsFromGeoJsonPointsFeatureCollection(geojson) {
  if (!geojson || !geojson.features || geojson.features.length === 0) {
    throw new Error('Invalid GeoJSON data');
  }

  let minLat = Infinity;
  let maxLat = -Infinity;
  let minLon = Infinity;
  let maxLon = -Infinity;

  geojson.features.forEach(feature => {
    const [lon, lat] = feature.geometry.coordinates;

    if (lat < minLat) minLat = lat;
    if (lat > maxLat) maxLat = lat;
    if (lon < minLon) minLon = lon;
    if (lon > maxLon) maxLon = lon;
  });

  // If min/max are equal, we have a single point. Let's pad it.
  if (minLat === maxLat && minLon === maxLon) {
    const delta = 0.0005; // Adjust this value to change the zoom level
    minLat -= delta;
    maxLat += delta;
    minLon -= delta;
    maxLon += delta;
  }

  return {
    southwest: [minLat, minLon],
    northeast: [maxLat, maxLon]
  };
}

export function getFeatureCollectionBounds(featureCollection) {
  // Ensure it's a FeatureCollection
  if (featureCollection.type !== 'FeatureCollection') {
    throw new Error('Input must be a GeoJSON FeatureCollection.');
  }

  let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

  function processCoordinates(coords) {
    if (typeof coords[0] === 'number' && typeof coords[1] === 'number') {
      const [x, y] = coords;
      if (x < minX) minX = x;
      if (y < minY) minY = y;
      if (x > maxX) maxX = x;
      if (y > maxY) maxY = y;
    } else {
      for (const c of coords) {
        processCoordinates(c);
      }
    }
  }

  for (const feature of featureCollection.features) {
    const geom = feature.geometry;
    if (!geom) continue;
    const { type, coordinates } = geom;

    switch (type) {
      case 'Point':
      case 'MultiPoint':
      case 'LineString':
      case 'MultiLineString':
      case 'Polygon':
      case 'MultiPolygon':
        processCoordinates(coordinates);
        break;
      default:
        console.warn(`Unsupported geometry type: ${type}`);
        break;
    }
  }

  // If min = max, we have a single point. Let's pad it similarly.
  if (minX === maxX && minY === maxY) {
    const delta = 0.0035; // Adjust for desired zoom level
    minX -= delta;
    maxX += delta;
    minY -= delta;
    maxY += delta;
  }

  return [minX, minY, maxX, maxY];
}
