import proj4 from 'proj4';
import mapboxgl from "mapbox-gl";
import {getLetterFromIrishGridRef, getLetterOSGB} from "../grid-ref/osgrid";
import {MeasurementPref} from "./MeasurementPref";

export enum GridRef {
  GbGrid,
  IeGrid,
  NzGrid,
  LatLon
}

export class GridRefUtils {
  gridRef: GridRef;

  constructor(gridRef: GridRef) {
    this.gridRef = gridRef
  }

  toPrettyString(): String {
    switch (this.gridRef) {
      case GridRef.GbGrid: return "Great Britain Grid Reference (ESPG 27700)";
      case GridRef.IeGrid: return "Irish Grid Reference (ESPG 29902)";
      case GridRef.NzGrid: return "New Zealand Grid Reference (ESPG 27200)"
      case GridRef.LatLon: return "World Geodetic System (Longitude, Latitude) (ESPG 4326)"
    }
  }

  toString(): String {
    switch (this.gridRef) {
      case GridRef.GbGrid: return "GbGrid";
      case GridRef.IeGrid: return "IeGrid";
      case GridRef.NzGrid: return "NzGrid";
      case GridRef.LatLon: return "LatLon";
    }
  }

  static fromString(gridRef: String): GridRefUtils {
    switch (gridRef) {
      case "GbGrid": return new GridRefUtils(GridRef.GbGrid);
      case "IeGrid": return new GridRefUtils(GridRef.IeGrid);
      case "NzGrid": return new GridRefUtils(GridRef.NzGrid);
      case "LatLon": return new GridRefUtils(GridRef.LatLon);
      default: return new GridRefUtils(GridRef.LatLon);
    }
  }

  static toProjection(gridRef: GridRef): String {
    switch (gridRef) {
      case GridRef.GbGrid: return "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs";
      case GridRef.IeGrid: return "+proj=tmerc +lat_0=53.5 +lon_0=-8 +k=1.000035 +x_0=200000 +y_0=250000 +ellps=mod_airy +towgs84=482.5,-130.6,564.6,-1.042,-0.214,-0.631,8.15 +units=m +no_defs";
      case GridRef.NzGrid: return "+proj=nzmg +lat_0=-41 +lon_0=173 +x_0=2510000 +y_0=6023150 +ellps=intl +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +units=m +no_defs";
      case GridRef.LatLon: return "+proj=longlat +datum=WGS84 +no_defs";
    }
  }

  static async setPreference(preference: GridRef) {
    // TODO: implement call to network
    console.log("call to network")
  }

  static preference(): GridRefUtils {
    if (window.user) {
      // @ts-ignore
      return GridRefUtils.fromString(window.user.grid_reference)
    } else if (window.gridRef) {
      return GridRefUtils.fromString(window.gridRef)
    } else {
      return new GridRefUtils(GridRef.LatLon)
    }
  }

  convert(lngLat: mapboxgl.LngLat): String {
    let currentProjection = GridRefUtils.toProjection(this.gridRef)
    // @ts-ignore
    let converted =  proj4(GridRefUtils.toProjection(GridRef.LatLon), currentProjection, [lngLat.lng, lngLat.lat]);
    let returnString = `${converted[0].toPrecision(6)}, ${converted[1].toPrecision(6)}`
    switch (this.gridRef) {
      case GridRef.GbGrid:
        return getLetterOSGB(converted[0], converted[1])
      case GridRef.IeGrid:
        return getLetterFromIrishGridRef(converted[0], converted[1])
      case GridRef.NzGrid:
        return returnString
      case GridRef.LatLon:
        return returnString
    }
  }

  formatFeedback(): String {
    switch (this.gridRef) {
      case GridRef.GbGrid: return "Should be in the format of LetterLetter space number space number e.g. NN 16604 71259";
      case GridRef.IeGrid: return "Should be in the format of Letter space number space number e.g. N 16604 71259";
      case GridRef.NzGrid: return "Should be in the format of number space number e.g. 16604 71259";
      case GridRef.LatLon: return "Should be in the format of number (longitude) space number (latitude) e.g. -6.5123 53.546";
    }
  }

  async convertLetteredGBGridRefToLatLng(gridRef: String): Promise<mapboxgl.LngLat> {
    let currentProjection = await GridRefUtils.preference()
    if (currentProjection.gridRef !== GridRef.GbGrid) {
      throw new Error("Grid Ref is not GB Grid")
    }
    let l1 = gridRef.toUpperCase().charCodeAt(0) - 'A'.charCodeAt(0);
    let l2 = gridRef.toUpperCase().charCodeAt(1) - 'A'.charCodeAt(0);
    // shuffle down letters after 'I' since 'I' is not used in grid:
    if (l1 > 7) l1 -= 1;
    if (l2 > 7) l2 -= 1;
    // convert grid letters into 100km-square indexes from false origin (grid square SV):
    const e100km = ((l1 - 2) % 5) * 5 + (l2 % 5);
    const n100km = (19 - Math.floor(l1 / 5) * 5) - Math.floor(l2 / 5);
    // skip grid letters to get numeric (easting/northing) part of ref
    const parts = gridRef.split(' ');
    const easting = parts[1];
    const northing = parts[2];

    let eastingFinalString = `${e100km}${easting}`;
    let northingFinalString = `${Math.round(n100km)}${northing}`;

    while (eastingFinalString.length < 6) {
      eastingFinalString += '0';
    }
    while (northingFinalString.length < 6) {
      northingFinalString += '0';
    }

    const eastingNorthing = {
      x: parseFloat(eastingFinalString),
      y: parseFloat(northingFinalString)
    };

    const convertEastingNorthingToLatLong = proj4(
      // @ts-ignore
      GridRefUtils.toProjection(GridRef.GbGrid),
      GridRefUtils.toProjection(GridRef.LatLon),
      [eastingNorthing.x, eastingNorthing.y]
    );
    return new Promise<mapboxgl.LngLat>((resolve, reject) => {
      resolve(new mapboxgl.LngLat(convertEastingNorthingToLatLong[0], convertEastingNorthingToLatLong[1]))
    })
  }

  convertLetteredIrishGridRefToLonLat(gridRef) {
    if (this.gridRef !== GridRef.IeGrid) {
      throw new Error("Grid Ref is not Irish Grid")
    }
    const letter = gridRef.charAt(0);
    const intArray = GridRefUtils.iEGridLetterToArray(letter);
    const splitGridRef = gridRef.split(" ");

    let easting = intArray[0].toString() + splitGridRef[1].toString();
    let northing = intArray[1].toString() + splitGridRef[2].toString();

    while (easting.length < 6) {
      easting += "0";
    }
    while (northing.length < 6) {
      northing += "0";
    }

    const eastingNorthing = {
      x: parseFloat(easting),
      y: parseFloat(northing)
    };
    const convertEastingNorthingToLatLong = proj4(
      // @ts-ignore
      GridRefUtils.toProjection(GridRef.IeGrid),
      GridRefUtils.toProjection(GridRef.LatLon),
      [eastingNorthing.x, eastingNorthing.y]
    );
    return new Promise<mapboxgl.LngLat>((resolve, reject) => {
      resolve(new mapboxgl.LngLat(convertEastingNorthingToLatLong[0], convertEastingNorthingToLatLong[1]))
    })
  }

  convertNzGridRefToLonLat(gridRef) {
    let split = gridRef.split(" ");
    let easting = split[0];
    let northing = split[1];

    while (easting.length < 7) {
      easting += "0";
    }
    while (northing.length < 7) {
      northing += "0";
    }

    const eastingNorthing = {
      x: parseFloat(easting),
      y: parseFloat(northing)
    };
    const convertEastingNorthingToLatLong = proj4(
      // @ts-ignore
      GridRefUtils.toProjection(GridRef.NzGrid),
      GridRefUtils.toProjection(GridRef.LatLon),
      [eastingNorthing.x, eastingNorthing.y]
    );
    return new Promise<mapboxgl.LngLat>((resolve, reject) => {
      resolve(new mapboxgl.LngLat(convertEastingNorthingToLatLong[0], convertEastingNorthingToLatLong[1]))
    })

  }

  static iEGridLetterToArray(letter) {
    const letterMap = {
      "A": [0, 4],
      "B": [1, 4],
      "C": [2, 4],
      "D": [3, 4],
      "E": [4, 4],
      "F": [0, 3],
      "G": [1, 3],
      "H": [2, 3],
      "J": [3, 3],
      "K": [4, 3],
      "L": [0, 2],
      "M": [1, 2],
      "N": [2, 2],
      "O": [3, 2],
      "P": [4, 2],
      "Q": [0, 1],
      "R": [1, 1],
      "S": [2, 1],
      "T": [3, 1],
      "U": [4, 1],
      "V": [0, 0],
      "W": [1, 0],
      "X": [2, 0],
      "Y": [3, 0],
      "Z": [4, 0],
    };

    if (letter in letterMap) {
      return letterMap[letter.toUpperCase()];
    } else {
      throw new Error("Invalid letter: " + letter);
    }
  }

  static validateGridRef(gridRef: string, gridRefType: GridRef): boolean {
    switch (gridRefType) {
      case GridRef.IeGrid:
        return gridRef.split(' ').length === 3
          && gridRef.split(' ')[0].length === 1
          && !isNaN(parseInt(gridRef.split(' ')[1]))
          && !isNaN(parseInt(gridRef.split(' ')[2]));

      case GridRef.GbGrid:
        return gridRef.split(' ').length === 3
          && gridRef.split(' ')[0].length === 2
          && !isNaN(parseInt(gridRef.split(' ')[1]))
          && !isNaN(parseInt(gridRef.split(' ')[2]));

      case GridRef.LatLon:
        return gridRef.split(' ').length === 2
          && !isNaN(parseFloat(gridRef.split(' ')[0]))
          && !isNaN(parseFloat(gridRef.split(' ')[1]));

      case GridRef.NzGrid:
        return gridRef.split(' ').length === 2
          && !isNaN(parseInt(gridRef.split(' ')[0]))
          && !isNaN(parseInt(gridRef.split(' ')[1]));

      default:
        throw new Error('Unknown grid reference preference');
    }
  }
}
