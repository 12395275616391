
function padLz(number, w) {
  var n = number.toString();
  var l = n.length;
  for (var i=0; i<w-l; i++) n = '0' + n;
  return n;
}

export function getLetterOSGB(easting: number, northing: number): string {
  let digits = 10
  let e = easting
  let n = northing
  if (e==NaN || n==NaN) return '??';

  // get the 100km-grid indices
  let e100k = Math.floor(e/100000), n100k = Math.floor(n/100000);

  if (e100k<0 || e100k>6 || n100k<0 || n100k>12) return '';

  // translate those into numeric equivalents of the grid letters
  let l1 = (19-n100k) - (19-n100k)%5 + Math.floor((e100k+10)/5);
  let l2 = (19-n100k)*5%25 + e100k%5;

  // compensate for skipped 'I' and build grid letter-pairs
  if (l1 > 7) l1++;
  if (l2 > 7) l2++;
  let letPair = String.fromCharCode(l1+'A'.charCodeAt(0), l2+'A'.charCodeAt(0));

  // strip 100km-grid indices from easting & northing, and reduce precision
  e = Math.floor((e%100000)/Math.pow(10,5-digits/2));
  n = Math.floor((n%100000)/Math.pow(10,5-digits/2));

  return letPair + ' ' + padLz(e, digits/2) + ' ' + padLz(n, digits/2);
}

export function getLetterFromIrishGridRef(xLon: number, yLat: number): String {
  let val1Full = xLon
  let val2Full = yLat
  let firstInt: number
  if (val1Full < 100000) {
    firstInt = 0
  } else {
    firstInt = parseInt((val1Full.toString().charAt(0) ?? "0"))
  }
  let secondInt: number
  if (val2Full < 100000) {
    secondInt = 0
  } else {
    secondInt = parseInt((val2Full.toString().charAt(0) ?? "0"))
  }
  let gridRefLetter = arrayToGridRef([firstInt, secondInt])
  if (gridRefLetter == 'Out of range' || val1Full < 0 || val2Full < 0) {
    return "Out of range"
  }


  let string1 = Math.round(val1Full).toString().substring(1)
  let string2 = Math.round(val2Full).toString().substring(1)
  if (val1Full < 100000) {
    string1 = Math.round(val1Full).toString()
  }
  if (val2Full < 100000) {
    string2 = Math.round(val2Full).toString()
  }

  return `${gridRefLetter} ${string1} ${string2}`
}

function arrayToGridRef(gridRefArray: number[]): String {
  switch (`${gridRefArray[0]} ${gridRefArray[1]}`) {
    case "1 0": return "W"
    case "0 0": return "V"
    case "2 0": return "X"
    case "3 0": return "Y"
    case "4 0": return "Z"
    case "0 1": return "Q"
    case "1 1": return "R"
    case "2 1": return "S"
    case "3 1": return "T"
    case "4 1": return "U"
    case "0 2": return "L"
    case "1 2": return "M"
    case "2 2": return "N"
    case "3 2": return "O"
    case "4 2": return "P"
    case "0 3": return "F"
    case "1 3": return "G"
    case "2 3": return "H"
    case "3 3": return "J"
    case "4 3": return "K"
    case "0 4": return "A"
    case "1 4": return "B"
    case "2 4": return "C"
    case "3 4": return "D"
    case "4 4": return "E"
    default: return "Out of range"
  }
}
