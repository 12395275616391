import {POIUtility} from "../models/POI";

export function createIndividualWaypointGeoJsonObjectHelper(clientId: string, e: any, poiType: POIUtility) {
  return {
    "type": "FeatureCollection",
    "features": [{
      "type": "Feature",
      "properties": {
        "title": 'Custom Waypoint',
        "clientId": clientId,
        "iconName": poiType.getSpriteSheetWaypointIcon(),
        "type": poiType.poi,
        "isWaypoint": true,
      },
      "geometry": {
        "type": "Point",
        "coordinates": [e.lng, e.lat]
      }
    }]
  }
}
