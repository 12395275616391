import {gql} from "graphql-request";

export const CreateWaypoint = gql`
  mutation createWaypoint($waypoint:CreateWaypointInput!) {
    createWaypoint(input:$waypoint) {
      id
      lonlat
      title
      type
      description
      coordinateReferenceSystem
      public
      obfuscatedId
      obfuscatedSlug
      createdAt
      updatedAt
      deletedAt
      clientId
      errors {
        fieldName
        errors
      }
    }
  }
`;

export const UpdateWaypoint = gql`
  mutation UpdateWaypoint($waypoint:UpdateWaypointInput!) {
    updateWaypoint(input:$waypoint) {
      id
      title
      waypointGroupIds
      errors {
        fieldName
        errors
      }
    }
  }
`;

export const DestroyWaypoint = gql`
  mutation DestroyWaypoint($waypoint:DestroyWaypointInput!) {
    destroyWaypoint(input:$waypoint) {
      id
      destroyed
      errors {
        fieldName
        errors
      }
    }
  }
`;

export const GetAllWaypointsForUser = gql`
  query GetAllWaypointsForUser {
    waypoints {
      id
      lonlat
      type
      clientId
      createdAt
      title
    }
  }
`;

export const WaypointsSearch = gql`
  query WaypointsSearch($searchString: String!) {
    waypointsSearch(searchString: $searchString) {
      id
      lonlat
      title
      type
      description
      coordinateReferenceSystem
      public
      obfuscatedId
      obfuscatedSlug
      createdAt
      updatedAt
      deletedAt
      clientId
    }
  }
`;

export const GetAllUserWaypointGroups = gql`
  query GetAllUserWaypointGroups {
    waypointGroups {
      id
      title
      obfuscatedId
      obfuscatedSlug
      createdAt
      updatedAt
      clientId
      waypoints {
        id
        lonlat
        title
        type
        description
        clientId
      }
    }
  }
`;

export const GetWaypointByIdOrClientId = gql`
  query GetWaypointByIdOrClientId($clientId: String!) {
    waypointByIdOrClientId(clientId: $clientId) {
      id
      lonlat
      title
      type
      description
      coordinateReferenceSystem
      public
      obfuscatedId
      obfuscatedSlug
      createdAt
      updatedAt
      deletedAt
      clientId
      userId
      waypointPhotos {
        id
        file {
          url
        }
      }
    }
  }
`;

export const DestroyWaypointImage = gql`
  mutation destroyWaypointPhoto($input: DestroyWaypointPhotoInput!) {
    destroyWaypointPhoto(input: $input) {
      id
      waypointPhotos {
        id
      }
    }
  }
`;

export const DestroyWaypointGroup = gql`
  mutation destroyWaypointGroup($waypointGroup:DestroyWaypointGroupInput!) {
    destroyWaypointGroup(input:$waypointGroup) {
      id
      destroyed
    }
  }
`;

export const UpdateWaypointGroup = gql`
  mutation updateWaypointGroup($waypointGroup:UpdateWaypointGroupInput!) {
    updateWaypointGroup(input:$waypointGroup) {
      id
      title
      obfuscatedId
      obfuscatedSlug
      errors {
        fieldName
        errors
      }
    }
  }
`;

export const CreateWaypointGroup = gql`
  mutation createWaypointGroup($waypointGroup:CreateWaypointGroupInput!) {
    createWaypointGroup(input:$waypointGroup) {
      id
      title
      obfuscatedId
      obfuscatedSlug
      errors {
        fieldName
        errors
      }
    }
  }
`;

export const GetWaypointGroupByIdOrClientId = gql`
  query GetWaypointGroupByIdOrClientId($clientId: String!) {
    waypointGroupByIdOrClientId(clientId: $clientId) {
      id
      title
      obfuscatedId
      obfuscatedSlug
      createdAt
      updatedAt
      waypoints {
        id
        lonlat
        title
        type
        description
        coordinateReferenceSystem
        public
        obfuscatedId
        obfuscatedSlug
        createdAt
        updatedAt
        deletedAt
        clientId
        waypointPhotos {
          id
          file {
            url
          }
        }
      }
    }
  }
`;

export const CreateWaypoints = gql`
  mutation createWaypoints($input: CreateWaypointsInput!) {
    createWaypoints(input: $input) {
      id
      lonlat
      title
      type
      description
      coordinateReferenceSystem
      public
      obfuscatedId
      obfuscatedSlug
      createdAt
      updatedAt
      deletedAt
      clientId
    }
  }
`;
