import {POI, POIUtility} from "../models/POI";
import {generateRandomInt} from "./IDGenerator";
import {createIndividualWaypointGeoJsonObjectHelper} from "./CreateIndividualWaypointGeoJsonObjectHelper";
import {createWaypointMapLayerHelper} from "./CreateWaypointMapLayerHelper";

export function addWaypointToMapHelper(poiType: POIUtility, clientId: string, lngLat: {lng: number, lat: number}): string {
  let randomId = "waypoint-" + generateRandomInt()
  function addWaypointToMap() {

  }
  window.map.addSource(randomId, {
    "type": "geojson",
    "data": createIndividualWaypointGeoJsonObjectHelper(clientId, lngLat, poiType)
  })
  // @ts-ignore
  window.map.addLayer(createWaypointMapLayerHelper(randomId));
  return randomId
}
